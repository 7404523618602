
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
// import { __values } from "tslib";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { Field, Form } from "vee-validate";
import { NAME, PHONE } from "@/helper/globalConst";
import { ElNotification } from "element-plus";
import * as Yup from "yup";

export default defineComponent({
  name: "AddUserModel",
  mixins: [Globals],

  components: {
    Form,
    Field,
  },
  props: {
    render: Boolean,
  },
  data() {
    return {
      requestbody: null,
      orgId: "",
      formflag: false,
      role: this.getOrgRole(),
      flag: false,
      reset: false,
      loading: false,
      buttonActive: false,
    };
  },

  methods: {
    resetform() {
      (this.$refs["resetForm"] as any).click();
    },
    checkRole() {
      if (this.role == "consumer") this.role = "C";
      else if (this.role == "manufacturer") this.role = "M";
      else if (this.role == "api_provider") this.role = "P";
      else if (this.role == "1SB") this.role = "P";
      return this.role;
    },
    addData(formData) {
      let attributedata;
      attributedata = JSON.parse(sessionStorage.getItem("attributedata"));
      attributedata.data.push(formData.data);
      sessionStorage.setItem("attributedata", JSON.stringify(attributedata));
      // this.loading = true;
      // this.buttonActive = true;
      // formData.organizationType = this.checkRole();
      // formData.organizationId = this.globalData.organizationId;
      // formData.data.orgRole = this.getOrgRole();
      // formData.data.organizationId = this.globalData.organizationId;

      // ApiService.post("/user", formData)
      //   .then((data) => {
      //     (this.loading = false),
      //       (this.buttonActive = false),
            (this.$refs["resetForm"] as any).click();
          window.location.reload(true);
          hideModal(this.addCustomerModalRef);
          this.$emit("changeTitle", "false");
          ElNotification({
            title: "Success",
            message: "New Attribute added!",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //     this.buttonActive = false;
      //     ElNotification({
      //       title: "Error",
      //       message: JSON.stringify(
      //         error.response.data["errors"][0]["errorMessage"]
      //       ),
      //       type: "error",
      //       duration: 2000,
      //       position: "top-right",
      //     });
      //   });
    },
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addCustomerModalRef = ref<null | HTMLElement>(null);
    // const loading = ref<boolean>(false);

    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        attributeId: "",
        name: "",
        values: "",
        range: "",
        dataType: "",
      },
    });

    const formValidation = Yup.object().shape({
      attributeId: Yup.string().required().label("Attribute Id"),
      name: Yup.string().required().label("Name"),
      dataType: Yup.string().required().label("Type"),
      range: Yup.string().required().label("Is Range"),
      values: Yup.string().label("Values"),

      // mobileNumber: Yup.string().trim()
      //   .min(10, "Mobile number should be exactly 10 digits")
      //   .max(10, "Mobile number should be exactly 10 digits")
      //   .matches(PHONE, "Mobile number must be valid")
      //   .required()
      //   .label("Mobile Number"),
    });

    return {
      formData,
      formValidation,
      formRef,
      addCustomerModalRef,
    };
  },
});
